<template>
  <template v-if="isMobile">
    <header v-if="search.toggle" class="kb-header" id="kb-header">
      <div class="header-component">
        <div class="header-column header-util left">
          <div class="util util-back">
            <button type="button" class="util-btn" @click="closeSearch"><i class="icon-history-back" /></button>
          </div>
        </div>
        <div class="header-column header-title w-100">
          <input type="text" v-model="search.word" placeholder="검색어를 입력하세요" @keyup.enter="clickSearch"/>
        </div>
        <div class="header-column header-util right">
          <div v-if="'board' === pageMd && selectedGrpSeqSn > 0" class="util">
            <button type="button" class="util-btn" @click="clickSearch"><i class="icon-search" /></button>
          </div>
        </div>
      </div>
    </header>
    <LxpMobileHeader v-else :title="`${'Mentoring' === targetName ? 'KB 멘토링' : 'KB 씀'}`">
      <template v-slot:left>
        <div v-if="'board' === pageMd && targetItem.distCrseSn > 0 && canWrite" class="util util-back">
          <div class="util util-download">
            <button class="kb-btn-text util-actions-yellow" @click="goBoardWrite">{{ 'Mentoring' === targetName ? '일지' : '글' }} 작성</button>
          </div>
        </div>
        <div v-if="'report' === pageMd && myReport.lrnTrgtGrpDtlSn > 0 && !myReport.selfIntro" class="util util-back">
          <div class="util util-download">
            <button class="kb-btn-text util-actions-yellow" @click="goReportWrite">보고서 작성</button>
          </div>
        </div>
      </template>
      <template v-slot:right>
        <div v-if="'board' === pageMd && selectedGrpSeqSn > 0" class="util">
          <button type="button" class="util-btn" @click="clickSearch"><i class="icon-search" /></button>
        </div>
      </template>
    </LxpMobileHeader>
  </template>
  <div class="main-header">
    <!-- main-header > header-top -->
    <div v-if="!isMobile" class="header-top main-component">
      <div class="header-column">
        <nav class="kb-breadcrumb">
          <ol class="kb-breadcrumb-list">
            <li v-for="(breadcrumb, index) in breadcrumbs" class="kb-breadcrumb-item" :class="{'is-active' : index === breadcrumbs.length - 1}" :key="index">
              <a href="javascript:" class="kb-breadcrumb-link no-pointer" :aria-current="index === breadcrumbs.length - 1 ? 'page' : ''"><span class="kb-breadcrumb-text">{{ breadcrumb }}</span></a>
            </li>
          </ol>
        </nav>
      </div>

      <div v-if="isMentor || mentorings.length > 1" class="header-column">
        <SortButtonSelect2
            v-model="selected"
            v-model:toggle="toggle"
            :options="mentorings"
            sequence-key="index"
            name-key="data"
            :is-num="true"
            button-class="kb-btn kb-btn-secondary kb-btn-sm rounded-lg"
            @selected="selectedMentoring"
        />
      </div>
    </div>
    <div v-else class="header-snb mb-0">
      <nav class="snb">
        <ul class="snb-list">
          <li v-for="(link, index) in links" class="snb-item" :class="{'is-active': view === link.key}" :key="index">
            <button @click="goMentoringTab(link.key)">{{ link.name }}</button>
          </li>
          <li v-if="isMentor || mentorings.length > 1" class="snb-item snb-item-divider">
            <SortButtonSelect3
                v-model="selected"
                v-model:toggle="toggle"
                :options="mentorings"
                sequence-key="index"
                name-key="data"
                :is-num="true"
                @selected="selectedMentoring"
            />
          </li>
        </ul>
      </nav>
    </div>

    <!-- main-header > header-title -->
    <div v-if="!isMobile" class="header-title">
      <h2 class="title">{{ title }}</h2>
    </div>

    <!-- main-header > header-tab -->
    <div v-if="!isMobile" class="header-tab">
      <MentoringRoute :links="links" :div="div"/>
    </div>
  </div>
</template>

<script>

import {computed, onMounted, reactive, ref, watch} from 'vue';
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import LxpMobileHeader from "@/views/layout/lxp/header/mobile/LxpMobileHeader";
import MentoringRoute from '@/components/prep/mentoring/MentoringRoute';
import SortButtonSelect2 from '@/components/common/SortButtonSelect2';
import SortButtonSelect3 from '@/components/common/SortButtonSelect3.vue';
import navigationUtils from "@/assets/js/navigationUtils";
import {grpRoleCdDcds} from '@/assets/js/modules/course/mentoring-common';
import {ACT_GET_CRSE_DIST_GRP_SEQ_LIST} from "@/store/modules/course/course";
import {getListFunc} from "@/assets/js/ui.init";

export default {
  name: 'MentoringHeader',
  components: {
    LxpMobileHeader,
    MentoringRoute,
    SortButtonSelect2,
    SortButtonSelect3,
  },
  emits: ['changeCurrentIndex', 'update:selectedGrpSeqSn'],
  props: {
    title: String,
    div: String,
    links: Array,
    breadcrumbs: Array,
    targetItem: Object,
    isMentor: Boolean,
    items: Array,
    currentIndex: Number,
    selectedGrpSeqSn: Number,
  },
  setup(props, {emit}) {
    const selected = ref(props.currentIndex);
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const session = computed(() => store.state.auth.session);
    const toggle = ref(false);
    const view = computed(() => route.params.view || 'main');
    const pageMd = computed(() => (view.value == 'board' || view.value == 'diary') ? 'board':view.value);
    const currentQuery = computed(() => route.query);
    const mentorings = computed(() =>
      props.items.map((x, index) => {
        const mentoring = x.learners.find(x => x.lrnerId != session.value.lrnerId);
        if(mentoring){
          let data = mentoring.grpRoleCdDcd == grpRoleCdDcds.leader ? '멘토' : '멘티';
          data = data + ' ' + mentoring.lrnerNm + ' / ' + x.distCrseGrpNm;
          return {
            index,
            data
          }
        }
      })
    );

    const targetName = computed(() => {
      if (route.name.indexOf('Mentoring') === 0) {
        return 'Mentoring';
      } else if(route.name.indexOf('SSM') === 0) {
        return 'SSM';
      }
      return 'Mentoring';
    });

    const search = reactive({
      toggle: false,
      word: '',
    });

    const goMentoringTab = (key) => {
      if('main' === key){
        emit('update:selectedGrpSeqSn', 0);
        router.push({ name: `${props.div}Main`, query: currentQuery.value });
      }else{
        if(key != 'board'){
          emit('update:selectedGrpSeqSn', 0);
        }
        router.push({ name: `${props.div}Sub`, params: {view: key}, query: currentQuery.value });
      }
    }

    const myReport = computed(() => {
      const target = props.targetItem.learners.find(x => x.lrnerId === session.value.lrnerId);
      return target || {lrnTrgtGrpDtlSn: 0}
    });

    const goBoardWrite = () => {
      if(targetName.value == 'SSM'){
        router.push({name: `${targetName.value}Write`, params: {distCrseSn: props.targetItem.distCrseSn, distCrseGrpSeqSn: props.selectedGrpSeqSn, view: view.value, targetSn: 0}, query: currentQuery.value});
      }else{
        router.push({name: `${targetName.value}Write`, params: {distCrseSn: props.targetItem.distCrseSn, view: view.value, targetSn: 0}, query: currentQuery.value});
      }
    }

    const goReportWrite = () => {
      if(myReport.value.lrnTrgtGrpDtlSn > 0){
        router.push({name: 'MentoringWrite', params: {
            distCrseSn: props.targetItem.distCrseSn,
            targetSn: myReport.value.lrnTrgtGrpDtlSn
          }, query: currentQuery.value});
      }
    }

    const selectedMentoring = () => {
      if(props.currentIndex !== selected.value) {
        emit('changeCurrentIndex', selected.value);
      }
    }

    const clickSearch = () => {
      if(search.toggle){
        if(search.word){
          router.push({query: {...currentQuery.value, search: search.word}});
        }else{
          closeSearch();
        }
      }else{
        search.toggle = true;
      }
    }

    const closeSearch = () => {
      router.push({query: {...currentQuery.value, search: ''}});
      search.word = '';
      search.toggle = false;
    }

    const seqs = ref([]);
    const canWrite = ref(false);
    const currentTimestamp = new Date().getTime();

    const getCourseSequenceList = () => {
      seqs.value = [];
      if(props.targetItem.distCrseSn > 0){
        getListFunc(`course/${ACT_GET_CRSE_DIST_GRP_SEQ_LIST}`, {distCrseSn: props.targetItem.distCrseSn, params: {distCrseGrpBoardTyDcd: '2162001'}}, seqs, null, getCanWrite);
      }
    }

    const getCanWrite = () => {
      if(props.selectedGrpSeqSn > 0 && seqs.value.length > 0){
        const targetSeq = seqs.value.find(seq => seq.distCrseGrpSeqSn == props.selectedGrpSeqSn);
        canWrite.value = targetSeq ? targetSeq.bgngDt <= currentTimestamp && currentTimestamp <= targetSeq.endDt : false;
      }else{
        canWrite.value = true;
      }
    }

    watch(() => props.targetItem.distCrseSn, getCourseSequenceList)

    watch(() => props.selectedGrpSeqSn, getCanWrite)

    onMounted(getCourseSequenceList)

    return {
      selected,
      toggle,
      mentorings,
      currentQuery,
      view,
      pageMd,
      myReport,
      targetName,
      search,
      canWrite,
      selectedMentoring,
      goMentoringTab,
      goBoardWrite,
      goReportWrite,
      clickSearch,
      closeSearch,
      isMobile: navigationUtils.any(),
    }
  }
};
</script>
