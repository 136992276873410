<template>
  <nav class="kb-nav-round-tabs">
    <ul class="kb-nav-list">
      <li v-for="(link, index) in links" class="kb-nav-item" :key="index">
        <router-link :to="link.key === 'main' ? { name: `${div}Main`, query: currentQuery } : { name: `${div}Sub`, params: {view: link.key}, query: currentQuery }" class="kb-nav-link" :class="{'is-active': view === link.key}">
          <span class="kb-nav-text">{{ link.name }}</span>
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>

import {computed} from 'vue';
import {useRoute} from 'vue-router';

export default {
  name: 'MentoringRoute',
  components: {
  },
  props: {
    div: String,
    links: Array
  },
  setup() {
    const route = useRoute();

    const currentQuery = computed(() => route.query);
    const view = computed(() => route.params.view || 'main')

    return {
      currentQuery,
      view
    }
  }
};
</script>
