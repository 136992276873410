<template>
  <div class="sdl-info-container">
    <!-- info-card -->
    <div v-if="mentor.lrnTrgtGrpDtlSn > 0" class="info-card">
      <a v-if="isMobile" href="javascript:" class="card-link"></a>
      <div class="card-avatar">
        <div class="badge badge-mentor"><span class="badge-text">멘토</span></div>
        <div class="avatar">
          <ProfileImg v-if="!isLoading" :target="{lrnerId: mentor.lrnerId}" />
        </div>
      </div>
      <div class="card-content">
        <h3 class="title">{{ mentor.lrnerNm }}</h3>
        <span class="subtitle">{{ mentor.deptNm }}</span>
      </div>
    </div>

    <!-- info-card -->
    <div v-if="mentee.lrnTrgtGrpDtlSn > 0" class="info-card">
      <a v-if="isMobile" href="javascript:" class="card-link"></a>
      <div class="card-avatar">
        <div class="badge badge-mentee"><span class="badge-text">멘티</span></div>
        <div class="avatar">
          <ProfileImg v-if="!isLoading" :target="{lrnerId: mentee.lrnerId}" />
        </div>
      </div>
      <div class="card-content">
        <h3 class="title">{{ mentee.lrnerNm }}</h3>
        <span class="subtitle">{{ mentee.deptNm }}</span>
      </div>
    </div>
  </div>
</template>

<script>

import {ref, watch} from 'vue';
import ProfileImg from '@/components/common/ProfileImg';
import navigationUtils from "@/assets/js/navigationUtils";

export default {
  name: 'MentoringMeta',
  components: {
    ProfileImg,
  },
  props: {
    mentor: Object,
    mentee: Object,
    targetItem: Object
  },
  setup(props) {
    const isLoading = ref(false);

    const toggleLoading = () => {
      isLoading.value = !isLoading.value;
    }

    watch(() => props.targetItem.distCrseSn, () => {
      toggleLoading();
      setTimeout(toggleLoading, 200);
    });

    return {
      isLoading,
      isMobile: navigationUtils.any(),
    }
  }
};
</script>
