<template>
  <div v-if="!isMobile" class="board-list-container">
    <!-- list-top -->
    <div class="list-top">
      <div class="top-column">
        <p class="title text-muted">총 {{paging.totalCount}}건</p>
      </div>
      <div class="top-column">
        <button v-if="targetItem.classCateSn > 0" class="kb-btn kb-btn-primary me-2" type="button" @click="goComBoard">
          <span class="text">멘토링Day 등록</span>
        </button>
        <button v-if="targetItem.distCrseSn > 0" class="kb-btn kb-btn-primary" type="button" @click="goWrite">
          <span class="text">{{ write }}</span>
        </button>
      </div>
    </div>
    <!-- board-top -->
    <template v-if="isReady">
      <template v-if="items.length > 0">
        <ul class="board-list divider-list">
          <li v-for="(item, index) in items" class="board-item divider-item" :key="index">
            <div class="item-card">
              <div class="card-content">
                <div class="content-title">
                  <div class="title d-flex align-items-center">
                    <a class="title-link kb-mouse-cursor" @click="clickBoardTitle(item)">{{ item.title }}</a>
                    <div v-if="item.stt == '01'" class="badge badge-silver ms-2"><span class="badge-text">작성중</span></div>
                  </div>
                </div>
                <div class="content-meta">
                  <div class="meta"><span class="meta-text">{{ timestampToDateFormat(item.regDt, 'yyyy.MM.dd hh:mm') }}</span></div>
                  <div class="meta"><span class="meta-text text-muted">{{ mentoringDivText(targetItem.learners, item.lrnerId) }} ・ {{ item.lrnerNm }}</span></div>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <CommonPaginationFront :paging="paging" :page-func="pagingFunc"/>
      </template>
      <div v-else class="search-container">
        <div class="result-empty">
          <img src="../../../assets/lxp/images/common/img_empty.png" alt="">
          <p class="text">{{ title }} 정보가 없습니다.</p>
        </div>
      </div>
    </template>
  </div>
  <div v-else class="notice-list-container">
    <!-- list-top -->
    <div class="list-top status">
      <div class="top-column">
        <p class="text text-muted">총 {{paging.totalCount}}건</p>
      </div>
      <div v-if="targetItem.classCateSn > 0" class="top-column">
        <button class="kb-btn kb-btn-xs kb-btn-outline-primary" type="button" @click="goComBoard"><span class="text">멘토링Day 등록</span></button>
      </div>
    </div>
    <!-- board-top -->
    <template v-if="isReady">
      <template v-if="items.length > 0">
        <div class="board-list-container">
          <ul class="board-list">
            <li v-for="(item, index) in items" class="board-list-item" :key="index">
              <a class="title-link w-100" @click="clickBoardTitle(item)">
                <div class="list-content w-100">
                  <div class="list-content-title">
                    <h5 class="title">
                      <span class="text text-truncate">{{ item.title }}</span>
                      <div v-if="item.stt == '01'" class="badge badge-silver ms-2"><span class="badge-text">작성중</span></div>
                    </h5>
                  </div>
                  <div class="list-meta-content">
                    <span class="text">{{ timestampToDateFormat(item.regDt, 'yyyy.MM.dd hh:mm') }}</span>
                    <span class="text">{{ mentoringDivText(targetItem.learners, item.lrnerId) }} ・ {{ item.lrnerNm }}</span>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>
        <CommonPaginationFront :paging="paging" :page-func="pagingFunc"/>
      </template>
      <div v-else class="search-container">
        <div class="result-empty">
          <img src="../../../assets/lxp/images/common/img_empty.png" alt="">
          <p class="text">{{ title }} 정보가 없습니다.</p>
        </div>
      </div>
    </template>


  </div>
</template>

<script>

import {computed, reactive, ref, watch} from 'vue';
import {getListFunc} from '@/assets/js/ui.init';
import {ACT_GET_LRN_BOARD_LIST} from '@/store/modules/board/board';
import {useAlert} from '@/assets/js/modules/common/alert';
import {timestampToDateFormat} from '@/assets/js/util';
import CommonPaginationFront from '@/components/CommonPaginationFront';
import {mentoringDivText} from '@/assets/js/modules/course/mentoring-common';
import {useRoute, useRouter} from 'vue-router';
import navigationUtils from "@/assets/js/navigationUtils";

export default {
  name: 'MentoringBoard',
  components: {
    CommonPaginationFront,
  },
  props: {
    targetItem: Object,
    title: String,
    write: String
  },
  setup(props) {

    const route = useRoute();
    const router = useRouter();
    const { showLoading, hideLoading } = useAlert();
    const isReady = ref(false);
    const view = computed(() => route.params.view);
    const isSSM = computed(() => route.name.indexOf('SSM') === 0);
    const items = ref([]);
    const paging = reactive({
      pageNo: 1,
      pageSize: 10,
      totalCount: 0
    });

    const tryCount = ref(0);

    const targetName = computed(() => {
      if (route.name.indexOf('Mentoring') === 0) {
        return 'Mentoring';
      } else if(isSSM.value) {
        return 'SSM';
      }
      return 'Mentoring';
    });

    const currentQuery = computed(() => route.query);

    const getMentoringBoardList = (isInit) => {
      if (tryCount.value > 0) {
        if(isInit) hideLoading();
        return;
      }

      if (props.targetItem.distCrseSn > 0){
        getListFunc(`board/${ACT_GET_LRN_BOARD_LIST}`, {distCrseSn: props.targetItem.distCrseSn, distCrseGrpYn: 'Y', stt: isSSM.value ? '01':'00'}, items, paging, () => {
          isReady.value = true;
          tryCount.value = 0;
          if(isInit) hideLoading();
        });
      }else{
        setTimeout(() => {
          getMentoringBoardList();
        }, 300)
        tryCount.value++;
      }
    }

    const pagingFunc = (pageNo) => {
      paging.pageNo = pageNo;
      getMentoringBoardList();
    }

    const goWrite = () => {
      router.push({name: `${targetName.value}Write`, params: {distCrseSn: props.targetItem.distCrseSn, view: view.value, targetSn: 0}, query: currentQuery.value});
    }

    const goComBoard = () => {
      router.push({name: 'BoardMstGrp', params: {boardId: 'ssm-board', postGrpNo: props.targetItem.classCateSn}, query: {'hisback': '1'}})
    }

    const clickBoardTitle = (item) => {
      if(item.stt == '01'){
        router.push({name: `${targetName.value}Write`, params: {distCrseSn: props.targetItem.distCrseSn, view: view.value, targetSn: item.lrnPostSn}, query: currentQuery.value});
      }else{
        router.push({name: `${targetName.value}View`, params: {view: view.value, targetSn: item.lrnPostSn}, query: currentQuery.value});
      }
    }

    showLoading();
    getMentoringBoardList(true);

    watch(() => props.targetItem, () => {
      if(props.targetItem && props.targetItem.distCrseSn > 0) {
        showLoading();
        paging.pageNo = 1;
        getMentoringBoardList(true);
      }
    });

    return {
      isReady,
      view,
      items,
      paging,
      targetName,
      currentQuery,
      isMobile: navigationUtils.any(),
      mentoringDivText,
      pagingFunc,
      goWrite,
      goComBoard,
      clickBoardTitle,
      timestampToDateFormat
    }
  }
};
</script>
